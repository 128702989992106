import request from '@/api/request'

export function fetchCategory() {
    return request({
        url: "/office-service/docCategory/thinktank/list",
    })
}

export function fetchList(data) {
    return request({
        url: "/office-service/docAdapter/thinktank/list",
        method: "post",
        data,
    })
}

export function add(data) {
    return request({
        url: '/office-service/docAdapter/thinktank/insert',
        method: 'post',
        data
    })
}

export function edit(data) {
    return request({
        url: '/office-service/docAdapter/thinktank/update',
        method: 'post',
        data
    })
}

export function remove(data) {
    return request({
        url: '/office-service/docAdapter/thinktank/delete',
        method: 'post',
        data,
    })
}

export function fetchDetail(data) {
    return request({
        url: '/office-service/docAdapter/thinktank/query',
        method: 'post',
        data
    })
}