<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="left category">
        <a-space>
          <span>资料分类：</span>
          <span class="item" v-for="(item, index) in categoryList" :key="index"
            :class="activeCategoryIndex === index ? 'active' : ''" @click="activeCategoryIndex = index">{{ item.name
            }}</span>
        </a-space>

        <div style="margin-left: auto">
          <a-button type="primary" v-if="$getPermission($route.path + '/add')"
            @click="$router.push($route.path + '/add')">新增</a-button>
        </div>
      </div>

      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="资料名称" />
        </a-form-model-item>

        <a-form-model-item>
          <a-select style="width: 150px" v-model="form.major" placeholder="专业类型">
            <a-select-option v-for="item in docMajorType" :key="item.value" :value="item.value">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-range-picker v-model="form.updateAt" style="width: 260px" :placeholder="['开始更新日期', '结束更新日期']" />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }">
        <a-table-column key="name" title="文件名称">
          <template slot-scope="text">
            <span>{{ text.name }}</span>
          </template>
        </a-table-column>

        <a-table-column key="level" title="专业类型" align="center" width="100px" v-if="isProduct">
          <template slot-scope="text">
            <DataDictFinder dictType="docMajorType" :dictValue="text.major" />
          </template>
        </a-table-column>

        <a-table-column key="createUser" title="更新人" align="center" width="80px">
          <template slot-scope="text">
            {{ text.updateBy ? text.updateBy : text.createBy }}
          </template>
        </a-table-column>

        <a-table-column key="createAt" title="更新日期" align="center" width="160px">
          <template slot-scope="text">
            {{ text.updateAt ? text.updateAt : text.createAt }}
          </template>
        </a-table-column>

        <a-table-column key="control" title="操作" align="center" width="180px">
          <template slot-scope="text">
            <a-space>
              <a href="#" v-if="$getPermission($route.path + '/preview')" @click.prevent="
                preview(text)
                ">预览</a>
              <a href="#" v-if="$getPermission($route.path + '/download')" @click.prevent="
                download(text)
                ">下载</a>
              <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent="
                $router.push(
                  $route.path + '/edit?id=' + text.id + '&pid=' + text.pid
                )
                ">修改</a>
              <a href="#" v-if="$getPermission($route.path + '/delete')" class="danger"
                @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchCategory, fetchList, remove } from "@/api/knowledge/think";
import { mapGetters } from "vuex";

import { PDFDocument, rgb, degrees } from 'pdf-lib';
import { saveAs } from 'file-saver'
import fontkit from '@pdf-lib/fontkit'

import moment from 'moment'

export default {
  name: "think",
  data() {
    return {
      categoryList: [],
      activeCategoryIndex: 0,

      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    activeCategoryIndex() {
      // 清空查询条件
      this.form = {};
      this.getList();
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    docMajorType() {
      return this.findDataDict("docMajorType");
    },

    isProduct() {
      const obj = this.categoryList[this.activeCategoryIndex]
      return obj ? obj.pid === '17' : false
    }
  },

  mounted() {

    fetchCategory().then((res) => {
      try {
        this.categoryList = Object.keys(res).map(key => {
          return {
            name: key,
            pid: res[key][0].id
          }
        })

        this.getList();

      } catch (error) {
        console.error(error)
      }

    });

  },

  methods: {
    getList() {
      const pid = this.categoryList[this.activeCategoryIndex]?.pid;
      const { current, pageSize } = this;
      const { updateAt } = this.form;

      const params = {};

      if (Array.isArray(updateAt) && updateAt.length === 2) {
        params.updateAtStartDate = updateAt[0].format("YYYY-MM-DD");
        params.updateAtEndDate = updateAt[1].format("YYYY-MM-DD");
      }
      this.loading = true;
      fetchList({
        pid,
        pageNum: current,
        pageSize,
        ...this.form,
        ...params,
        updateAt: undefined,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
            pid: text.pid,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },

    preview(text) {
      const path = text.attachmentList[0].completePath;
      const isPdf = /\.pdf$/i.test(path);

      if (isPdf) {
        window.localStorage.setItem('think-pdf-name', text.name);
        window.localStorage.setItem('think-pdf-path', path)
        this.$router.push(this.$route.path + '/preview')
      } else {
        this.$message.error("不是PDF，暂时无法预览")
      }
    },

    async download(text) {

      try {
        this.$message.info("下载任务已发起，请检查浏览器下载内容")
        const path = text.attachmentList[0].completePath;

        // 读取pdf文件
        const existingPdfBytes = await fetch(path).then(res => res.arrayBuffer());

        // 创建一个新的pdf文档
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const userStr = window.localStorage.getItem('user');
        if (!userStr) return;
        const user = JSON.parse(userStr);

        pdfDoc.registerFontkit(fontkit)
        const fontBytes = await fetch('/pdf_font.ttf').then(res => res.arrayBuffer());
        const customFont = await pdfDoc.embedFont(fontBytes);

        // 添加水印
        // const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        const watermarkText = `NJMD ${user.userName} ${moment().format("YYYY-MM-DD HH:mm")}`
        const watermarkSize = 18;
        const watermarkColor = rgb(0.75, 0.75, 0.75);
        // const textWidth = customFont.widthOfTextAtSize(watermarkText, watermarkSize);
        // const textHeight = customFont.heightAtSize(watermarkSize);

        const pages = pdfDoc.getPages();

        pages.forEach(page => {

          const width = page.getWidth();
          const height = page.getHeight();

          for (let i = 12; i <= width; i = i + 200) {
            for (let j = 40; j <= height; j = j + 250) {
              page.drawText(watermarkText, {
                x: i,
                y: j,
                size: watermarkSize,
                font: customFont,
                color: watermarkColor,
                opacity: 0.8,
                rotate: degrees(30),
              });
            }
          }


        })

        // 将修改后的pdf文档转换为blob对象
        const pdfBytesWithWatermark = await pdfDoc.save();
        const blob = new Blob([pdfBytesWithWatermark], { type: 'application/pdf' });

        saveAs(blob, `${text.name}-${user.userName}.pdf`);


      } catch (error) {
        console.error(error)
        this.$message.error(error.toString())
      }




    }
  },
};
</script>

<style lang="less" scoped>
.category {
  margin-bottom: 16px;

  .item {
    cursor: pointer;
    padding: 4px 8px;
  }

  .active {
    background-color: #1890ff;
    color: #fff;
  }
}
</style>